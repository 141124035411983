<template>
  <v-container fluid>
    <v-card>
      <v-layout>
        <v-list-item three-line>
          <v-layout>
            <v-img max-height="100" max-width="100" :size="40" :src="Selfie"></v-img>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                DANH SÁCH CHẤM CÔNG</v-list-item-title>
              <v-list-item-subtitle>Danh sách chấm công của nhân viên</v-list-item-subtitle>
            </v-list-item-content>
          </v-layout>
        </v-list-item>
        <v-layout style="width: 1350px" class="pr-4">
          <v-menu ref="menu" v-model="showDate" :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                clearable placeholder="Chọn từ ngày đến ngày"></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="showDate = false">
                Hủy
              </v-btn>
              <v-btn text color="primary" @click="changeDate()" :disabled="!this.date || this.date.length != 2">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <!-- <v-btn color="#33691E" class="ml-4 mt-3" @click="exportExcel">
            <v-avatar size="30" class="mr-2">
              <img :src="excelPic" />
            </v-avatar>
            <span style="color: white" class="btn-add">Xuất Excel</span>
          </v-btn> -->

          <v-menu key="Removed" rounded="0" offset-y max-height="250px">
            <template v-slot:activator="{ on }">
              <v-btn color="#33691E" v-on="on" class="ml-4 mt-3" :disabled="loadingExportChecking">
                <v-avatar size="30" class="mr-2" v-if="!loadingExportChecking">
                  <img :src="excelPic" />
                </v-avatar>
                <v-progress-circular v-else class="mr-2" indeterminate color="amber"></v-progress-circular>

                <span style="color: white" class="btn-add">Xuất Excel</span>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="exportExcel">
                <v-list-item-title> Danh sách chấm công</v-list-item-title>
              </v-list-item>
              <v-list-item @click="notCheckingList">
                <v-list-item-title>
                  DS Nhân viên chưa chấm công</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportExcelError">
                <v-list-item-title> Xuất báo cáo lỗi</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn color="#33691E" class="ml-4 mt-3" @click="showFormXuat">
            <v-avatar size="30" class="mr-2">
              <img :src="excelPic" />
            </v-avatar>

            <span style="color: white" class="btn-add">Xuất Báo cáo Tháng</span>
          </v-btn>
          <!-- <v-menu
            ref="menu_month_picker"
            v-model="menu_excel_month"
            :close-on-content-click="false"
            :return-value.sync="date_month"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="#33691E"
                class="ml-4 mt-3"
                v-on="on"
                :disabled="loadingExportReport"
              >
                <v-avatar size="30" class="mr-2" v-if="!loadingExportReport">
                  <img :src="excelPic" />
                </v-avatar>
                <v-progress-circular
                  v-else
                  class="mr-2"
                  indeterminate
                  color="amber"
                ></v-progress-circular>

                <span style="color: white" class="btn-add"
                  >Xuất Báo cáo Tháng</span
                >
              </v-btn>
            </template>
            <v-date-picker
              v-model="date_month"
              type="month"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu_excel_month = false">
                Đóng
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu_month_picker.save(date_month);
                  exportExcelMonth(date_month);
                "
              >
                Xuất
              </v-btn>
            </v-date-picker>
          </v-menu> -->
        </v-layout>
      </v-layout>
    </v-card>
    <br />
    <v-card class="mb-1">
      <v-card-title class="pa-6 pb-4 mb-3">
        <div style="width: 100%" class="mr-4">
          <v-row>
            <v-col cols="2">
              <v-select v-model="trang_thai" item-text="name" item-value="value" :items="trangThai" multiple chips
                placeholder="Trạng thái" hide-details clearable @change="searchData"></v-select>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="phong_ban_id" item-text="name" item-value="id" :items="phongBans"
                placeholder="Phòng ban" hide-details clearable :filter="fullTextFilter" prepend-inner-icon="mdi-city"
                @change="searchData"></v-autocomplete>
            </v-col>
            <!-- <v-col cols="2">
              <v-autocomplete
                v-model="loai_nhom"
                item-text="name"
                item-value="value"
                :items="types"
                placeholder="Loại nhóm"
                hide-details
                :filter="fullTextFilter"
                clearable
                @change="getData"
              ></v-autocomplete>
            </v-col> -->
            <v-col cols="2">
              <v-autocomplete v-model="dia_diem" item-text="name" item-value="id" :items="danhSachDiaDiem"
                placeholder="Địa điểm" hide-details multiple :filter="fullTextFilter" clearable @change="searchData">
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="nhom_to_id" item-text="name" item-value="id" :items="nhomTos"
                placeholder="Nhóm tổ" hide-details :filter="fullTextFilter" clearable
                prepend-inner-icon="mdi-account-multiple" @change="searchData"></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="chuc_vu_id" item-text="name" item-value="id" :items="chucVus"
                placeholder="Chức vụ" hide-details :filter="fullTextFilter" clearable @change="searchData">
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select v-model="ca" item-text="name" item-value="id" :items="caLamViec" multiple chips
                placeholder="Ca làm việc" hide-details clearable @change="searchData"></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field append-icon="mdi-magnify" v-model="search" label="Tìm kiếm" clearable single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :page.sync="page" :items-per-page="itemsPerPage"
        hide-default-footer :loading="loading" class="elevation-1" loading-text="Đang tải dữ liệu ..."
        @update:options="sortTable" :multi-sort="true">
        <template v-slot:[`item.name`]="{ item }">
          <v-layout align-center>
            <v-avatar color="indigo" size="40">
              <img v-if="item.url_image" :src="imageEndpoint + item.url_image" alt="ManhLe" />
              <span style="color: white" v-else-if="item && item.name">{{
              item.name.charAt(0).toUpperCase()
              }}</span>
              <v-icon v-else dark>mdi-account</v-icon>
            </v-avatar>
            <div class="ml-5">
              <div style="font-size: 16px">{{ item.name }}</div>
              <div style="color: gray">Mã NV: {{ item.ma_nhan_vien }}</div>
            </div>
          </v-layout>
        </template>
        <template v-slot:[`item.ngay`]="{ item }">
          {{ formatDate(item.time_check_in) }}
        </template>
        <template v-slot:[`item.dia_diem`]="{ item }">
          <div><b>Checkin:</b> {{ item.dia_diem }}</div>
          <div v-if="item.time_check_out">
            <b>Checkout:</b> {{ item.dia_diem_check_out }}
          </div>
        </template>
        <template v-slot:[`item.hop_le`]="{ item }">
          <div v-if="new Date(item.time_check_in) < new Date('2021-12-01')">
            <v-chip v-if="item.hop_le" color="success" dark small>
              Hợp lệ
            </v-chip>
            <v-chip v-else color="pink" dark small> Không hợp lệ </v-chip>
          </div>

          <div v-else>
            <v-chip :color="trangThaiChamCong(item).color" dark small>{{
            trangThaiChamCong(item).name
            }}</v-chip>
          </div>
        </template>
        <template v-slot:[`item.time_check_in`]="{ item }">
          <v-layout column class="pb-3 pt-1" align-center>
            <v-img class="image-box" :src="imageEndpoint + item.url_image_check_in" max-height="100" max-width="130">
              <div class="chip-time">
                <v-chip small dark color="secondary darken-3">
                  <v-icon left small> mdi-clock </v-icon>
                  <strong>{{ formatTime(item.time_check_in) }}</strong>
                </v-chip>
              </div>
            </v-img>
          </v-layout>
        </template>
        <template v-slot:[`item.time_check_out`]="{ item }">
          <v-layout column class="pb-3 pt-1" align-center>
            <v-img class="image-box" :src="imageEndpoint + item.url_image_check_out" max-height="100" max-width="130">
              <div class="chip-time">
                <v-chip small dark color="secondary darken-3">
                  <v-icon left small> mdi-clock </v-icon>
                  <strong>{{ formatTime(item.time_check_out) }}</strong>
                </v-chip>
              </div>
            </v-img>
          </v-layout>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <router-link :to="'/chamcong/chitiet/' + item.id">
            <v-btn color="primary" fab x-small dark>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
    <div class="pt-2">
      <v-pagination v-model="page" :length="pageCount" @input="changePage" :total-visible="10"></v-pagination>
    </div>
    <create-edit ref="thaisan" @on-done="getData"></create-edit>
    <v-dialog v-model="showExport" width="600">
      <v-card>
        <v-card-title> XUẤT BÁO CÁO CHẤM CÔNG </v-card-title>
        <v-card-text>
          <div style="color: black; font-size: 16px; font-weight: bold" class="pb-3">
            Thời gian (Tháng/Năm)
          </div>
          <v-date-picker v-model="thoi_gian_bao_cao" type="month" full-width></v-date-picker>
          <div style="color: black; font-size: 16px; font-weight: bold" class="pb-3 pt-6">
            Địa điểm - đơn vị làm việc
          </div>
          <v-autocomplete v-model="dia_diem_bao_cao" item-text="name" item-value="id" outlined dense
            :items="danhSachDiaDiem" placeholder="Toàn bộ địa điểm" hide-details :filter="fullTextFilter" clearable>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions class="pr-8 pb-3">
          <v-btn color="orange" @click="showExport = false">
            <v-icon left>mdi-close</v-icon>
            Đóng
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="exportExcelBaoCao" :disabled="loadingExportReport">
            <v-avatar size="30" class="mr-2" v-if="!loadingExportReport">
              <img :src="excelPic" />
            </v-avatar>
            <v-progress-circular v-else class="mr-2" indeterminate color="amber"></v-progress-circular>

            Xuất báo cáo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { debounce } from "lodash";
import {
  getList,
  exportChamCong,
  exportChamCongMonth,
  exportChuaChamCong,
  exportError
} from "@/api/chamcong";
import CreateEdit from "./create-edit";
import Selfie from "../../../assets/images/selfie.png";
import { getPhongBan } from "@/api/phongban";
import { getNhomToTrucThuoc } from "@/api/nhomto";
import { getDanhMucCon } from "@/api/danhmuc";
import excelPic from "../../../assets/images/excel.png";
import { saveAs } from "file-saver";
import { fullTextFilter } from "../../../utils/stringHelper";
import { LOAINHOM } from "@/api/nhomto";
import { getDiaDiem } from "@/api/diadiem";
import { getCa } from "@/api/calamviec"
export default {
  components: { CreateEdit },
  data() {
    return {
      fullTextFilter,
      showExport: false,
      date_month: new Date().toISOString().substr(0, 7),
      menu_excel_month: false,
      types: LOAINHOM,
      danhSachDiaDiem: [],
      dia_diem: [],
      Selfie,
      excelPic,
      page: 1,
      date: [],
      showDate: false,
      pageCount: 1,
      itemsPerPage: 10,
      totalDesserts: 0,
      tableData: [],
      btnLoading: false,
      loading: false,
      search: "",
      imageEndpoint: process.env.VUE_APP_BASE,
      phongBans: [],
      nhomTos: [],
      phong_ban_id: null,
      nhom_to_id: null,
      chuc_vu_id: null,
      chucVus: [],
      trang_thai: null,
      ca:null,
      loai_nhom: null,
      dia_diem_bao_cao: null,
      thoi_gian_bao_cao: new Date().toISOString().substr(0, 7),
      dateRangeText: "",
      loadingExportReport: false,
      loadingExportChecking: false,
      sortBy: [],
      trangThai: [
        { name: "Không hợp lệ", value: 0 },
        { name: "Hợp lệ", value: 1 },
        { name: "Chờ duyệt", value: 2 },
        { name: "Đã duyệt", value: 3 },
        { name: "Từ chối duyệt", value: 4 },
        // { name: "Chưa chấm công", value: 5 },
        { name: "Chưa Checkout", value: 6 },
        { name: "Checkin muộn", value: 7 },
      ],
      caLamViec:[],
      headers: [
        { text: "Họ tên", value: "name", sortable: false, width: "250px" },
        {
          text: "Ngày",
          align: "start",
          sortable: false,
          value: "ngay",
        },
        {
          text: "Ca làm việc",
          align: "start",
          sortable: false,
          value: "ca",
        },
        {
          text: "Chức vụ",
          align: "start",
          sortable: false,
          value: "chuc_vu",
        },
        {
          text: "Nhóm tổ",
          align: "start",
          sortable: false,
          value: "nhom_to",
        },
        {
          text: "Địa điểm",
          align: "start",
          sortable: false,
          value: "dia_diem",
        },
        {
          text: "CHECK IN",
          align: "center",
          sortable: true,
          value: "time_check_in",
          width: "200",
        },
        {
          text: "CHECK OUT",
          align: "center",
          sortable: true,
          value: "time_check_out",
          width: "200",
        },
        // {
        //   text: "Tăng ca",
        //   align: "center",
        //   sortable: false,
        //   value: "tang_ca",
        //   width: "100",
        // },
        {
          text: "Trạng thái",
          align: "center",
          sortable: true,
          value: "hop_le",
        },
        {
          text: "Chi tiết",
          value: "action",
          sortable: false,
          align: "center",
          width: "120",
        },
      ],
    };
  },
  created() {
    this.date = [this.getDateNow(), this.getDateNow()];
    this.getData();
    this.getDanhMuc();
    this.getDanhSachDiaDiem();
    this.getCaLamViec();
  },
  watch: {
    date(val) {
      if (val && val.length == 2) {
        if (new Date(this.date[0]) > new Date(this.date[1])) {
          let temp = this.date[0];
          this.date[0] = this.date[1];
          this.date[1] = temp;
        }
        let newDate = [...this.date];
        newDate[0] = this.formatDate(newDate[0]);
        newDate[1] = this.formatDate(newDate[1]);
        this.dateRangeText = newDate.join(" - ");
      }
    },
    dateRangeText(val) {
      if (val == null) {
        this.date = null;
        this.getData();
      }
    },
    thoi_gian_bao_cao(val) {
      console.log(val);
    },
    search: debounce(async function (val) {
      this.loading = true;
      this.page = 1;
      let data = await getList({
        perPage: this.itemsPerPage,
        search: val,
        phong_ban_id: this.phong_ban_id,
        trang_thai: this.trang_thai,
        nhom_to_id: this.nhom_to_id,
        chuc_vu_id: this.chuc_vu_id,
        loai_nhom: this.loai_nhom,
        date:
          this.date && this.date.length
            ? [new Date(this.date[0]), new Date(this.date[1])]
            : null,
      });
      this.loading = false;
      this.tableData = data.data;
      this.pageCount = data.meta.last_page;
    }, 300),
  },
  methods: {
    sortTable(val) {
      let sort = val.sortBy;
      let desc = val.sortDesc;
      this.sortBy = [];
      sort.forEach((element, indx) => {
        this.sortBy.push({ name: element, desc: desc[indx] })
      });
      this.getData()
    },
    trangThaiChamCong(data) {
      let hopLe = data.hop_le ? "Hợp lệ" : "Không hợp lệ";
      let pheDuyet =
        data.trang_thai_duyet == "cho_duyet"
          ? "Chờ duyệt"
          : data.trang_thai_duyet == "da_duyet"
            ? "Đã duyệt"
            : "Từ chối";
      let name = hopLe + " - " + pheDuyet;
      if (data.hop_le && data.trang_thai_duyet == "cho_duyet") {
        return {
          name: name,
          color: "primary",
        };
      }
      if (data.hop_le && data.trang_thai_duyet == "da_duyet") {
        return {
          name: name,
          color: "success",
        };
      }
      if (data.hop_le && data.trang_thai_duyet == "tu_choi") {
        return {
          name: name,
          color: "pink",
        };
      }
      if (!data.hop_le) {
        return {
          name: name,
          color: "pink",
        };
      }
      return {
        name: name,
        color: "primary",
      };
    },
    async exportExcel() {
      try {
        this.loadingExportChecking = true;
        const res = await exportChamCong({
          page: this.page,
          perPage: this.itemsPerPage,
          phong_ban_id: this.phong_ban_id,
          search: this.search,
          trang_thai: this.trang_thai,
          nhom_to_id: this.nhom_to_id,
          chuc_vu_id: this.chuc_vu_id,
          dia_diem: this.dia_diem,
          date:
            this.date && this.date.length
              ? [new Date(this.date[0]), new Date(this.date[1])]
              : null,
        });
        saveAs(new Blob([res]), `Cham_cong.xlsx`);
        this.loadingExportChecking = false;
      } catch (error) {
        this.loadingExportChecking = false;
      }
    },
    showFormXuat() {
      this.thoi_gian_bao_cao = new Date().toISOString().substr(0, 7);
      this.dia_diem_bao_cao = null;
      this.showExport = true;
    },
    async exportExcelBaoCao() {
      try {
        this.loadingExportReport = true;
        const res = await exportChamCongMonth({
          date: this.thoi_gian_bao_cao,
          dia_diem: this.dia_diem_bao_cao,
        });
        this.loadingExportReport = false;
        saveAs(new Blob([res]), `Cham_cong_${this.thoi_gian_bao_cao}.xlsx`);
        this.showExport = false;
      } catch (error) {
        this.loadingExportReport = false;
        console.log(error);
      }
    },
    async notCheckingList() {
      if (!this.date || this.date.length < 2) {
        this.$toast.warning("Vui lòng chọn thời gian", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      try {
        this.loadingExportChecking = true;
        const res = await exportChuaChamCong({
          page: this.page,
          perPage: this.itemsPerPage,
          phong_ban_id: this.phong_ban_id,
          search: this.search,
          trang_thai: this.trang_thai,
          nhom_to_id: this.nhom_to_id,
          chuc_vu_id: this.chuc_vu_id,
          dia_diem: this.dia_diem,
          date:
            this.date && this.date.length
              ? [new Date(this.date[0]), new Date(this.date[1])]
              : null,
        });
        let start = this.formatDate(new Date(this.date[0]));
        let end = this.formatDate(new Date(this.date[1]));
        saveAs(new Blob([res]), `KO_ChamCong_${start}_den_${end}.xlsx`);
        this.loadingExportChecking = false;
      } catch (error) {
        this.loadingExportChecking = false;
      }
    },
    async exportExcelError(){
      console.log(2)
      if (!this.date || this.date.length < 2) {
        this.$toast.warning("Vui lòng chọn thời gian", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        return;
      }
      try {
        this.loadingExportChecking = true;
        const res = await exportError({
          page: this.page,
          perPage: this.itemsPerPage,
          phong_ban_id: this.phong_ban_id,
          search: this.search,
          trang_thai: this.trang_thai,
          nhom_to_id: this.nhom_to_id,
          chuc_vu_id: this.chuc_vu_id,
          dia_diem: this.dia_diem,
          date:
            this.date && this.date.length
              ? [new Date(this.date[0]), new Date(this.date[1])]
              : null,
        });
        let start = this.formatDate(new Date(this.date[0]));
        let end = this.formatDate(new Date(this.date[1]));
        saveAs(new Blob([res]), `Loi_ChamCong_${start}_den_${end}.xlsx`);
        this.loadingExportChecking = false;
      } catch (error) {
        this.loadingExportChecking = false;
      }
    },
    async exportExcelMonth(date_month) {
      try {
        this.loadingExportReport = true;
        const res = await exportChamCongMonth({
          date: date_month,
        });
        this.loadingExportReport = false;
        saveAs(new Blob([res]), `Cham_cong_${this.date_month}.xlsx`);
      } catch (error) {
        this.loadingExportReport = false;
        console.log(error);
      }
    },
    searchData() {
      this.page = 1;
      this.getData();
    },
    async getCaLamViec(){
      let data= await getCa({
        perPage: 1000
      });
      this.caLamViec= data.data
    },
    async getData() {
      this.loading = true;
      let data = await getList({
        page: this.page,
        perPage: this.itemsPerPage,
        phong_ban_id: this.phong_ban_id,
        search: this.search,
        trang_thai: this.trang_thai,
        nhom_to_id: this.nhom_to_id,
        chuc_vu_id: this.chuc_vu_id,
        loai_nhom: this.loai_nhom,
        dia_diem: this.dia_diem,
        sort_by: this.sortBy,
        ca_lam_viec: this.ca,
        date:
          this.date && this.date.length
            ? [new Date(this.date[0]), new Date(this.date[1])]
            : null,
      });
      this.tableData = data.data;
      this.loading = false;
      this.pageCount = data.meta.last_page;
      this.getNhomTo();
    },
    async getDanhMuc() {
      let phongbans = await getPhongBan({
        perPage: 999,
      });
      this.chucVus = await getDanhMucCon({ code: "CHUCVU" });
      this.phongBans = phongbans.data;
    },
    async getNhomTo() {
      if (this.phong_ban_id) {
        let data = await getNhomToTrucThuoc({
          phong_ban_id: this.phong_ban_id,
        });
        this.nhomTos = data;
      } else this.nhomTos = [];
    },
    async getDanhSachDiaDiem() {
      let data = await getDiaDiem({
        perPage: 9999,
      });
      this.danhSachDiaDiem = data.data;
    },
    formatDate(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return ngay + "/" + thang + "/" + date.getFullYear();
      } catch (error) {
        return "";
      }
    },

    formatTime(e) {
      if (!e) return null;
      try {
        let date = new Date(e);
        let gio =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let phut =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return gio + "h " + phut;
      } catch (error) {
        return "";
      }
    },


    changePage(val) {
      this.page = val;
      this.getData();
    },
    edit(menu) {
      this.$refs.thaisan.showFormEdit(menu);
    },
    create() {
      this.$refs.thaisan.showFormAdd();
    },

    getDateNow() {
      let e = Date.now();
      if (!e) return null;
      try {
        let date = new Date(e);
        let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let thang =
          Number(date.getMonth() + 1) < 10
            ? "0" + Number(date.getMonth() + 1)
            : Number(date.getMonth() + 1);
        return date.getFullYear() + "-" + thang + "-" + ngay;
      } catch (error) {
        return "";
      }
    },
    changeDate() {
      this.$refs.menu.save(this.date);
      this.page = 1;
      this.getData();
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .btn-add {
    display: none;
  }
}

.image-box {
  border-radius: 6px;
  border: 1px solid #1a5276;
}

.chip-time {
  position: absolute;
  top: 3px;
  left: 3px;
}
</style>
